<template>
    <section class="clasification py-4">
        <div class="d-middle px-3">
            <div class="mr-3">
                <p class="f-15 text-black pl-3 wM-428px">Es la forma de clasificar a los lecheros de acuerdo a su desempeño.</p>
            </div>
            <button class="btn btn-general h-36px w-137px" @click="nuevoItem">
                Añadir
            </button>
        </div>
        <div class="br-12 px-3 py-4 bg-white my-4 wM-521px" style="box-shadow: 1px 1px 4px #00000014;">
            <!-- Lista de clasificaciones -->
            <div class="custom-scroll overflow-auto align-items-center" style="height: calc(100% - 140px);">
                <draggable :list="data" handle=".handle" @change="actualizarPosiciones">
                    <div v-for="(item, idx) in data" :key="idx" class="row mx-0 align-items-center" :class="idx > 0 ? 'mt-2' : ''">
                        <i class="icon-drag f-30 cr-pointer handle text-muted" />
                        <div class="col-auto">
                            <img :src="item.imagen" style="width: 45px; height:45px" />
                        </div>
                        <div class="col-6 px-0">
                            <p class="f-18 text-general">{{ item.nombre }}</p>
                        </div>
                        <div class="col-auto">
                            <el-tooltip class="item" effect="light" content="Editar" placement="bottom">
                                <div class="btn-general-2 border d-middle-center cr-pointer br-10" style="width:35px;height:35px;" @click="irEditar(item)">
                                    <i class="icon-pencil-outline f-20 text-general" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-0">
                            <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                <div class="btn-general-2 border d-middle-center cr-pointer br-10" style="width:35px;height:35px;" @click="confirmarEliminar(item.id)">
                                    <i class="icon-trash-empty f-20 text-general" />
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="refCrearClasificacion" :titulo="!modoEditar ? 'Crear clasificación' : 'Editar clasificación'" no-aceptar adicional="Guardar" @adicional="validarAccion">
            <cargando v-if="cargandoCrear" />
            <ValidationObserver ref="validacion">
                <ValidationProvider v-slot="{errors}" rules="required|max:25" name="Nombre de la clasificación">
                    <div class="row mx-0 ml-5 mr-5">
                        <p class="text-general f-13 mx-2">Nombre de la clasificación</p>
                        <el-input v-model="nombre" placeholder="Nombre" size="small" maxlength="25" class="w-100 justify-center" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </div>
                </ValidationProvider>
                <div class="row mx-0 my-4 justify-center">
                    <slim-cropper ref="imagen" :options="slimOptions" class="border" style="cursor:pointer;height:180px;background:#F8F9FF;width:180px;border-radius:12px;" />
                    <p class="f-12 text-center w-100">Relación de aspecto 1:1</p>
                    <p class="f-12 text-center w-100">Resolución mínima: 150 px * 150 px</p>
                    <span v-if="validImage" class="text-danger w-100 f-11 text-center">La imagen es obligatoria</span>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="refConfirmarEliminar" titulo="Eliminar categoría" no-aceptar adicional="Eliminar" @adicional="eliminarClasificacion">
            <div class="row mx-0 align-items-center">
                <div class="col text-center">
                    <p class="text-general">
                        ¿Desea eliminar la categoría? Esta acción no puede ser revertida.
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Service from '~/services/configurar/admin/descuentosLista';
export default {
    data(){
        return {
            data: [],
            idEliminar: null,
            nombre: null,
            cargandoCrear: false,
            modoEditar: false,
            validImage: false,
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    mounted(){
        this.getClasificaciones();
    },
    methods: {
        async getClasificaciones(){
            try {
                const {data} = await Service.getClasificaciones();
                this.data = data.data;
            } catch(e){
                this.error_catch(e);
            }
        },
        async nuevoItem(){
            await this.limpiar();
            this.modoEditar = false;
            this.$refs.refCrearClasificacion.toggle();
        },
        async validarAccion(){
            this.validImage = false;
            let image = this.$refs.imagen.get_image();

            const valid = await this.$refs.validacion.validate();
            	if(!valid || !image){
                if(!image){
                    this.validImage = true;
                }
                return;
            }

            this.cargandoCrear = true;
            const payload = {
                nombre: this.nombre,
                imagen: image
            };

            if(this.modoEditar){
                this.editarClasificacion(payload);
            } else {
                this.guardarClasificacion(payload);
            }
        },
        async guardarClasificacion(payload){
            try {
                const {data} = await Service.postClasificacion(payload);
                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.data.push(data.nuevo);
                    this.limpiar();
                    this.$emit('nuevo');
                    this.$refs.refCrearClasificacion.toggle();
                }
            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargandoCrear = false;
            }
        },
        async irEditar(data){
            this.modoEditar = true;
            this.idEditar = data.id;
            this.nombre = data.nombre;
            this.$refs.imagen.set_image(data.imagen);
            this.$refs.refCrearClasificacion.toggle();
        },
        async eliminarClasificacion(){
            try {
                if(!this.idEliminar)return;

                const {data} = await Service.deleteClasificacion(this.idEliminar);
                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    let index = this.data.findIndex(item => item.id === this.idEliminar);
                    if(index !== -1){
                        this.data.splice(index, 1);
                    }
                    this.$emit('eliminar', this.idEliminar);
                    this.$refs.refConfirmarEliminar.toggle();
                    this.idEliminar = null;
                }
            } catch(e){
                this.error_catch(e);
            }
        },
        async confirmarEliminar(id){
            this.idEliminar = id;
            this.$refs.refConfirmarEliminar.toggle();
        },
        async actualizarPosiciones(){
            try {
                const payload = {
                    ids: _.map(this.data, 'id'),
                };
                const {data} = await Service.actualizarPosiciones(payload);
                if(data.exito){
                    this.$emit('ordenar');
                }
            } catch(e){
                this.error_catch(e);
            }
        },
        limpiar(){
            this.nombre = null;
            this.$refs.validacion.reset();
            this.$refs.imagen.instanciaCrop.remove();
        },
        regresar(){
            this.$emit('regresar');
        },
    }
}
</script>
<style lang="scss" scoped>
.wM-428px{
	max-width: 428px;
}
.wM-521px{
	max-width: 521px;
}
</style>
